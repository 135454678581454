<template>
    <div style="display: none;" class="mbb-placeholder"/>
</template>

<script setup>
import { onMounted } from 'vue';
import api from '../api.js';

window.MBB = { seo: "false", data: { acid: "" }};

window.mbbMapLoaded = function() {
    window.MBB.googleMaps = true;

    // Create script tag for Buying Buddy after Google Maps is loaded
    let script = document.createElement('script');
    script.id = 'bbjs';
    script.src = 'https://d2w6u17ngtanmy.cloudfront.net/scripts/my-buying-buddy.5.0.js.gz';
    document.head.appendChild(script);
};

onMounted(() => {
    if (!window.bb_initialized) {
        api.getSettings((data) => {
            window.MBB.data.acid = data.mbb_acid;

            // Create script tag for Buying Buddy
            let script = document.createElement('script');
            script.src = `https://www.mbb2.com/version3/css/theme/acid/${data.mbb_acid}`;
            document.head.appendChild(script);

            // Create script tag for Google Maps
            script = document.createElement('script');
            script.async = true;
            script.src = `https://maps.googleapis.com/maps/api/js?callback=mbbMapLoaded&loading=async&libraries=places,marker&key=${data.google_maps_api_key}`;
            document.head.appendChild(script);
            window.bb_initialized = true;
        });
    }
});
</script>
