<template>
    <div>
        <EasyDataTable
            :headers="headers"
            :items="items"
            alternating
            @click-row="contact = $event"
            table-class-name="customize-table"
        />
        <div v-if="contact" class="modal-overlay" @click.self="closeModal" @show="onModalShow">
            <div class="modal">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ contact.name }}
                    </h5>
                    <button type="button" class="close" @click="closeModal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="align-left px200">
                        <strong>Email</strong>
                        <div class="mb-1">
                            <a :href="`mailto:${contact.email}`" v-if="contact.email">{{ contact.email }}</a>
                        </div>
                        <span v-if="contact.phone">
                            <strong>Phone</strong>
                            <div class="mb-1">
                                <a :href="`tel:${contact.phone}`" v-if="contact.phone">{{ contact.phone }}</a>
                            </div>
                        </span>
                        <span v-if="contact.message">
                            <strong>Message</strong>
                            <div class="message-scroll mb-3">{{ contact.message }}</div>
                        </span>
                        <span v-if="contact.address">
                            <strong>Address</strong>
                            <div class="mb-1">{{ contact.address }}</div>
                        </span>
                        <span v-if="contact.propertySize">
                            <strong>Property Size</strong>
                            <div class="mb-1">{{ contact.propertySize }}</div>
                        </span>
                        <span v-if="contact.bedrooms">
                            <strong>Bedrooms</strong>
                            <div class="mb-1">{{ contact.bedrooms }}</div>
                        </span>
                        <span v-if="contact.bathrooms">
                            <strong>Bathrooms</strong>
                            <div class="mb-1">{{ contact.bathrooms }}</div>
                        </span>

                        <strong>Date</strong>
                        <div class="mb-1">{{ new Date(contact.created_at).toLocaleString() }}</div>
                        <strong>IP Address</strong>
                        <div class="mb-1">{{ contact.ip }}</div>
                        <span v-if="contact.country">
                            <strong>Country</strong>
                            <div class="mb-1">{{ contact.country }}</div>
                        </span>
                        <span v-if="contact.region">
                            <strong>Region</strong>
                            <div class="mb-1">{{ contact.region }}</div>
                        </span>
                        <span v-if="contact.city">
                            <strong>City</strong>
                            <div class="mb-1">{{ contact.city }}</div>
                        </span>
                    </div>
                    <div class="full-minus-px200">
                        <div id="map" style="width: 100%; height: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from '../../api.js';
import { onMounted, ref, onUpdated, onUnmounted } from 'vue';
import { strings } from '../../strings.js';
import { Loader } from '@googlemaps/js-api-loader';

const headers = [
    { text: 'Name', value: 'name', sortable: true },
    { text: 'Email', value: 'email', sortable: true },
    { text: 'Phone', value: 'phone', sortable: true },
    { text: 'Message', value: 'truncatedMessage', sortable: true },
    { text: 'Date', value: 'created_at', sortable: true },
    { text: 'IP', value: 'ip', sortable: true },
    { text: 'Country', value: 'country', sortable: true },
    { text: 'Region', value: 'region', sortable: true },
    { text: 'City', value: 'city', sortable: true },
];

const items = ref([]);
const contact = ref(null);
const zoom = ref(8);

const loader = ref(null);

function initMap() {
    loader.value.importLibrary('maps').then(async () => {
        const { Map } = await loader.value.importLibrary('maps');
        const map =new Map(document.getElementById('map'), {
            center: { lat: 38.934343, lng: -77.119758 },
            zoom: zoom.value,
            mapId: 'Contact',
        });

        if (contact.value) {
            const { AdvancedMarkerElement } = await loader.value.importLibrary('marker');
            if (contact.value.lat && contact.value.lon) {
                new AdvancedMarkerElement({
                    map,
                    position: { lat: contact.value.lat, lng: contact.value.lon },
                    title: `${contact.value.name} (${strings.ApproximateLocation})`,
                });
                map.setCenter({ lat: contact.value.lat, lng: contact.value.lon });
            }
        }
    });
}

onUpdated(() => {
    if (document.getElementById('map')) {
        initMap();
    }
});

onMounted(() => {
    api.fetchContacts((data) => {
        items.value = data.map(item => {
            var full_contact = JSON.parse(item.json_contact);
            item.message = full_contact.message;
            item.address = full_contact.address;
            item.propertySize = full_contact.propertySize;
            item.bedrooms = full_contact.bedrooms;
            item.bathrooms = full_contact.bathrooms;
            if (full_contact.message) {
                if (full_contact.message.length > 40) { 
                    item.truncatedMessage = full_contact.message.substring(0, 37) + '...';
                } else {
                    item.truncatedMessage = full_contact.message;
                }
            }
            return item;
        });
    });

    api.getSettings((data) => {
        loader.value = new Loader({
            apiKey: data.google_maps_api_key,
        });
    });

    // Add event listener for 'Escape' key
    window.addEventListener('keydown', handleEscapeKey);
});

onUnmounted(() => {
    // Clean up the event listener when the component is unmounted
    window.removeEventListener('keydown', handleEscapeKey);
});

function handleEscapeKey(event) {
    if (event.key === 'Escape' && contact.value) {
        closeModal();
    }
}

function closeModal() {
    contact.value = null;
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-overlay .modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 75%;
    max-width: 90%;
    max-height: 75%;
    display: block;
    position: relative;
}

.modal-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-body {
    position: absolute !important;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
}

.modal-body > .row > .col-md-3 {
    overflow-y: auto;
}

.message-scroll {
    max-height: 200px;
    overflow-y: auto;
}

.close {
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

</style>
