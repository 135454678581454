<template>
    <div class="text-content" v-html="content"></div>
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import api from '../api.js';

const content = ref(strings.Loading);

onMounted(() => {
  api.fetchContent("about", (response) => { content.value = response; });
});
</script>
