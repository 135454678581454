<template>
    <div class="text-content">
        <h1>{{ strings.LoginTitle }}</h1>
        <form class="form-group border p-3" @submit.prevent="handleSubmit">
            <div class="mb-3">
                <label for="username" class="form-label">{{ strings.UsernameLabel }}</label>
                <input type="text" id="username" class="form-control" v-model="username" required />
            </div>
            <div class="mb-3">
                <label for="password" class="form-label">{{ strings.PasswordLabel }}</label>
                <input type="password" id="password" class="form-control" v-model="password" required />
            </div>
            <div class="mb-3">
                <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
            </div>
            <div class="d-flex justify-content-between">
                <button type="submit" class="btn btn-primary">{{ strings.SubmitButton }}</button>
                <button type="button" class="btn btn-link" @click="handleCancel">{{ strings.CancelButton }}</button>
            </div>
        </form>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { strings } from '../strings.js';
import { useRouter } from 'vue-router';
import api from '../api.js';

const username = ref('');
const password = ref('');
const errorMessage = ref('');
const router = useRouter();

localStorage.removeItem('token');

function handleSubmit() {
    api.login(username.value, password.value, () => {
        router.push('/admin/content');
    }, (error) => {
        errorMessage.value = error;
    });
}

function handleCancel() {
    router.push('/');
}

watch([username, password], () => {
    errorMessage.value = '';
});
</script>

<style scoped>
h1 {
    text-align: center;
}
.form-group {
    border: 1px solid #ccc; /* Thin border around the form */
    border-radius: 5px; /* Optional: rounded corners */
    width: 400px;
    margin: 0 auto;
    text-align: left;
}
</style>