<template>
    <div class="container">
        <form class="contact-form border row p-4 rounded mt-4" @submit.prevent="handleSubmit">
            <div class="col-md-6">
                <div v-html="contactTitle"></div>
            </div>
            <div class="col-md-6">
                <div v-html="contactContent"></div>
            </div>
            <div class="col-md-6">
                <div class="mb-1">
                    <label for="name" class="form-label">{{ strings.NameLabel }} <span class="text-danger">*</span></label>
                    <input type="text" id="name" class="form-control" v-model="contact.name" required :placeholder="strings.NamePlaceholder" />
                </div>
                <div class="mb-1">
                    <label for="phone" class="form-label">{{ strings.PhoneLabel }}</label>
                    <input type="text" id="phone" class="form-control" v-model="contact.phone" :placeholder="strings.PhonePlaceholder" />
                </div>
                <div class="mb-1">
                    <label for="email" class="form-label">{{ strings.EmailLabel }} <span class="text-danger">*</span></label>
                    <input type="email" id="email" class="form-control" v-model="contact.email" required :placeholder="strings.EmailPlaceholder" />
                </div>
                <div class="mb-3">
                    <label for="message" class="form-label">{{ strings.MessageLabel }}</label>
                    <textarea id="message" class="form-control" v-model="contact.message" :placeholder="strings.MessagePlaceholder"></textarea>
                </div>
            </div>
            <div class="col-md-6 mt-2">
                <div class="mb-3" v-html="listHomeHeader"></div>
                <div class="mb-1">
                    <label for="address" class="form-label">{{ strings.AddressLabel }}</label>
                    <input type="text" id="address" class="form-control" v-model="contact.address" :placeholder="strings.AddressPlaceholder" />
                </div>
                <div class="row">
                    <div class="mb-1 col-md-4">
                        <label for="propertySize" class="form-label">{{ strings.PropertySizeLabel }}</label>
                        <input type="number" id="propertySize" class="form-control" v-model="contact.propertySize" :placeholder="strings.PropertySizePlaceholder" />
                    </div>
                    <div class="mb-1 col-md-4">
                        <label for="bedrooms" class="form-label">{{ strings.BedroomsLabel }}</label>
                        <input type="number" id="bedrooms" class="form-control" v-model="contact.bedrooms" :placeholder="strings.BedroomsPlaceholder" />
                    </div>
                    <div class="mb-1 col-md-4">
                        <label for="bathrooms" class="form-label">{{ strings.BathroomsLabel }}</label>
                        <input type="number" id="bathrooms" class="form-control" v-model="contact.bathrooms" :placeholder="strings.BathroomsPlaceholder" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4 d-flex justify-content-between">
                    <button type="submit" class="btn btn-primary">{{ strings.SubmitButton }}</button>
                    <button type="reset" class="btn btn-link">{{ strings.ResetButton }}</button>
                </div>
                <div class="col-md-4"></div>
            </div>
        </form>
    </div>
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import api from '../api.js';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import { useRouter } from 'vue-router';

const router = useRouter();
const toast = useToast();

const contactTitle = ref('');
const contactContent = ref('');
const listHomeHeader = ref('');

const contact = {
    name: '',
    phone: '',
    email: '',
    message: '',
    address: '',
    propertySize: '',
    bedrooms: '',
    bathrooms: '',
};

function handleSubmit() {
    api.createContact(contact, () => {
        toast.success(strings.ContactSubmitted);
        // sleep for 5 seconds before redirecting
        setTimeout(() => {
            router.push('/');
        }, 5000);
    }, () => {
        toast.error(strings.ContactError);
    });
}

onMounted(() => {
    api.fetchContent("contactTitle", (response) => { contactTitle.value = response; });
    api.fetchContent("contactContent", (response) => { contactContent.value = response; });
    api.fetchContent("listHomeHeader", (response) => { listHomeHeader.value = response; });
});
</script>

<style scoped>
.form-label {
    text-wrap: nowrap;
}
</style>