<template>
    <div class="tabs">
        <div class="px100 header">
            <div :class="['tab', { active: $route.name === 'Content' }]" @click="$router.push({ name: 'Content' })">Content</div>
            <div :class="['tab', { active: $route.name === 'Users' }]" @click="$router.push({ name: 'Users' })">Users</div>
            <div :class="['tab', { active: $route.name === 'Contacts' }]" @click="$router.push({ name: 'Contacts' })">Contacts</div>
        </div>
        <div class="full-minus-px100">
            <RouterView />
        </div>
    </div>
</template>
