<template>
    <div class="main-content">
        <div v-if="route.name === 'HomeFinder'">
            <div v-if="content" v-html="content"></div>
        </div>
        <div v-if="route.name === 'HomeSearchResults'">
            <div id="MBBv3_ListingResults"></div>
        </div>
        <div v-else-if="route.name === 'HomeDetails'">
            <div id="MBBv3_SearchDetails"></div>
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { onMounted, ref } from 'vue';
import api from '../api';

const route = useRoute();

const content = ref(null);

onMounted(() => {
    if (route.name === 'HomeFinder') {
        api.fetchContent('homeFinder', (data) => {
            content.value = data;
        });
    }
});
</script>

<style scoped>
.main-content {
    width: 100%;
    margin: 0 20px 0 20px;
}
</style>