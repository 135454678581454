import { createRouter, createWebHistory } from 'vue-router';

import Home from './components/Home.vue';
import About from './components/About.vue';
import Admin from './components/Admin.vue';
import Blog from './components/Blog.vue';
import Contact from './components/Contact.vue';
import HomeFinder from './components/HomeFinder.vue';
import Login from './components/Login.vue';
import Content from './components/admin/Content.vue';
import Users from './components/admin/Users.vue';
import Contacts from './components/admin/Contacts.vue';

const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/about', name: 'About', component: About },
    { path: '/contact', name: 'Contact', component: Contact },
    { path: '/homes', name: 'HomeFinder', component: HomeFinder,
      children: [
        { path: 'results', name: 'HomeSearchResults', component: HomeFinder },
        { path: 'details', name: 'HomeDetails', component: HomeFinder }
      ]
    },
    { path: '/admin', name: 'Admin', component: Admin, meta: { requiresAuth: true },
      children: [
        { path: 'content', name: 'Content', component: Content },
        { path: 'users', name: 'Users', component: Users },
        { path: 'contacts', name: 'Contacts', component: Contacts }
      ]
    },
    { path: '/login', name: 'Login', component: Login },
    { path: '/:pathMatch(.*)*', name: 'Blog', component: Blog }
  ]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;