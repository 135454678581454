<template>
    <div class="row">
        <div class="col-md-6">
            <EasyDataTable
                :headers="headers"
                :items="items"
                alternating
                @click-row="originalUser = { ...$event }; updatedUser = { ...$event }"
                table-class-name="customize-table"
            />
            <div class="spacer"></div>
            <div class="d-flex justify-content-between">
                <button class="btn btn-primary" @click="addUser">Add User</button>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card" v-if="updatedUser.id || !originalUser && updatedUser">
                <div class="card-body">
                    <div class="mb-1">
                        <label class="form-label" for="name">Name</label>
                        <input type="text" class="form-control" id="name" v-model="updatedUser.name" required @input="updated = true" />
                    </div>
                    <div class="mb-1">
                        <label class="form-label" for="email">Email</label>
                        <input type="email" class="form-control" id="email" v-model="updatedUser.email" required @input="updated = true" />
                    </div>
                    <div class="mb-1">
                        <label class="form-label" for="username">Username</label>
                        <input type="text" class="form-control" id="username" v-model="updatedUser.username" required @input="updated = true" />
                    </div>
                    <div class="mb-1">
                        <label class="form-label" for="role">Role</label>
                        <select class="form-control" id="role" v-model="updatedUser.role" :disabled="originalUser && currentUser == originalUser.username" required @input="updated = true">
                            <option value="admin">Admin</option>
                            <option value="editor">Editor</option>
                        </select>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="password">Password</label>
                        <input type="password" class="form-control" id="password" v-model="updatedUser.password" required @input="passwordUpdated = updated = true" />
                    </div>
                    <div class="mb-3" v-if="error">
                        <div class="alert alert-danger">{{ error }}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <button class="btn btn-primary" @click="saveUser" :disabled="!updated">Save</button>
                        <button class="btn btn-link" @click="deleteUser" v-if="originalUser">Delete</button>
                    </div>                        
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import api from '../../api.js';
import { onMounted, ref } from 'vue';

const headers = [
    { text: 'Name', value: 'name', sortable: true },
    { text: 'Email', value: 'email', sortable: true },
    { text: 'Username', value: 'username', sortable: true },
    { text: 'Role', value: 'role', sortable: true },
];

const currentUser = api.isAuthenticated().username;

const items = ref([]);
const originalUser = ref({});
const updatedUser = ref({});
const updated = ref(false);
const passwordUpdated = ref(false);

const error = ref(null);

onMounted(() => {
    api.listUsers((data) => {
        items.value = data;
    });
});

const saveUser = () => {
    if (!updated.value) {
        return;
    }

    const updateRequest = { ...updatedUser.value };
    if (!passwordUpdated.value) {
        updateRequest.password = null;
    }

    api.saveUser(updateRequest, () => {
        updated.value = false;
        passwordUpdated.value = false;
        error.value = null;

        if (originalUser.value && updatedUser.value.username != originalUser.value.username) {
            alert("Username has been updated. Logging out.");
            api.logout();
        } else {
            api.listUsers((data) => {
                items.value = data;
            });
            originalUser.value = { ...updatedUser.value };
        }
    }, (errorResponse) => {
        errorResponse.text().then((errorMessage) => {
            error.value = errorMessage;
        });
    });
}

const addUser = () => {
    updatedUser.value = {};
    originalUser.value = null;
}

const deleteUser = () => {
    if (window.confirm("Are you sure you want to delete this user?")) {
        api.deleteUser(originalUser.value.id, () => {
            api.listUsers((data) => { items.value = data; });
            originalUser.value = {};
            updatedUser.value = {};
            updated.value = false;
            passwordUpdated.value = false;
            error.value = null;
        }, (errorMessage) => {
            error.value = errorMessage;
        });
    }
}
</script>

