<template>
    <div class="text-content" v-html="content" />
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import api from '../api.js';

const content = ref(strings.Loading);

onMounted(() => {
  api.fetchContent("home", (response) => { content.value = response; });
});
</script>

<style scoped>
.main-content {
    width: 100%;
}
</style>
