<template>
    <div>
        <h1>{{ strings.Blog }}</h1>
        <div v-html="content"></div>
    </div>
</template>

<script setup>
import { strings } from '../strings.js';
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import api from '../api.js';

const content = ref(strings.Loading);

const router = useRouter();
const route = useRoute();

onMounted(() => {
  router.isReady().then(() => {
    api.fetchContent(route.path, (response) => { content.value = response; });
  })

  router.afterEach((to, from) => {
    if (to.path !== from.path) {
      api.fetchContent(to.path, (response) => { content.value = response; });
    }
  });
});
</script>
